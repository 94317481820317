import React from "react";
import "./affirm.styles.scss";

const AffirmPage = () => {
  return (
    <div className="affirm-page-container">
      <div className="header">
        <h1> Herico Jewelry X Affirm</h1>
        <p>We will soon be offering Affirm as a payment integration</p>
        <p>Continue to check back here for updates!</p>
      </div>
    </div>
  );
};

export default AffirmPage;
