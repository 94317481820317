import React from "react";
import "./seasons-deals.styles.scss";

const SeasonsDealsPage = () => {
  return (
    <div className="seasons-deals-page-container">
      <h1>COMING SOON!</h1>
    </div>
  );
};
export default SeasonsDealsPage;
