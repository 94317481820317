import React from "react";
import "./seasons-deals-mobile.styles.scss";

const SeasonsDealsPageMobile = () => {
  return (
    <div className="seasons-deals-page-container-mobile">
      <h1>COMING SOON!</h1>
    </div>
  );
};
export default SeasonsDealsPageMobile;
